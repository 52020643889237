/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'font-awesome/css/font-awesome.css';
@import 'assets/reset';
@import 'app/KPIs/kpi';
@import 'colors';
@import 'fusion-theme';

$primary: $fusion-color-primary;
$kendo-button-border-width: 0;
$kendo-button-border-radius: 15px;
$kendo-font-size: 14px;

@import '@progress/kendo-theme-default/dist/all.scss';

body,
html {
  font-family: 'Open Sans', sans-serif !important;
  height: 100%;
  width: 100%;
  margin: 0;
  color: $textColor;
  font-size: 14px;
  background: #edf0f0;

  .list-items {
    list-style-type: disc;
    margin-left: 15px;
  }

  .chip-margin {
    margin-left: 25px;
  }

  .info-box {
    background-color: #fcfcfc;
    border: 1px solid #aab8c6;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    padding: 10px;
    line-height: 1.6em;
    width: fit-content;
    color: rgb(74, 74, 74);
  }

  .warning-box {
    border: 1px solid #ffeaae;
    background-color: #fffdf6;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    padding: 10px;
    line-height: 1.6em;
    width: fit-content;
    color: rgb(74, 74, 74);
  }

  .k-action-buttons {
    gap: 0;
    justify-content: center;
  }

  .k-list-optionlabel {
    display: flex;
    align-items: center;
  }

  .k-list-optionlabel,
  .k-list .k-list-item {
    height: auto;
    min-height: 40px;
  }
  .k-selected.k-list-optionlabel,
  .k-list-item.k-selected,
  .k-list-optionlabel.k-state-selected,
  .k-list .k-list-item.k-state-selected {
    color: black;
    background: #b3d4fc;
  }

  .k-selected.k-list-optionlabel:hover,
  .k-list-optionlabel:hover,
  .k-list-optionlabel.k-state-selected:hover,
  .k-list .k-list-item:hover,
  .k-list .k-list-item.k-state-selected:hover {
    color: black;
    background: #c7ebff;
  }

  .k-list-item.k-focus,
  .k-list-optionlabel.k-state-focused,
  .k-list .k-list-item.k-state-focused {
    box-shadow: none;
  }

  .k-grid {
    max-height: 50vh;
    min-height: 100%;
    overflow: hidden;
    color: #000000;
    font-size: 14px;

    a {
      color: #0078b9;
    }

    .k-grid-container {
      overflow: auto;
    }

    tbody {
      tr {
        max-height: 50px;

        td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
        }

        &.k-alt,
        &.k-table-alt-row,
        &.k-selected > .k-table-td,
        &.k-state-selected > td {
          background: none !important;
          color: #000000;
        }

        &:hover,
        &.k-state-hover,
        &.k-hover {
          background: none !important;
          color: rgba(0, 0, 0, 0.54);
        }

        &.k-detail-row {
          background: $fusion-color-white;
        }
      }
    }

    th {
      font-weight: 700 !important;
      padding-block: 10px !important;
      font-family: Roboto, sans-serif !important;
      color: rgba(0, 0, 0, 0.54) !important;
      padding-inline: 24px !important;
      font-size: 12px !important;
      line-height: 2;
      white-space: pre-line;
      text-overflow: clip;
    }

    .k-grid-header {
      background: white;
      color: rgba(0, 0, 0, 0.54);
      font-size: 12px;
      border-top-width: 0;
      padding: 0 !important;
    }

    .k-table-td {
      border: none;
      padding-block: 10px !important;
      padding-inline: 24px !important;
    }
    td {
      border-width: 0 0 1px 0;
      color: #4a4a4a;
      height: 50px;

      &.rightAligned {
        text-align: right;
      }

      &.k-state-selected {
        color: #000000;
        background-color: #ffffff;
      }
    }

    .pipelineDateAndStatus {
      display: flex;
      align-items: center;
      /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
      .mdc-evolution-chip-set {
        margin-left: 9px !important;
      }
    }

    .k-grid-content {
      overflow-x: auto;
      overflow-y: auto;
    }

    .k-hierarchy-cell .k-plus::before {
      font-family: FontAwesome, sans-serif;
      content: '\f105';
    }

    .k-hierarchy-cell .k-minus::before {
      font-family: FontAwesome, sans-serif;
      content: '\f107';
    }

    .k-hierarchy-cell > .k-icon {
      height: auto;
    }

    .k-grouping-row .k-i-expand::before {
      font-family: FontAwesome, sans-serif;
      content: '\f105';
    }

    .k-grouping-row .k-i-collapse::before {
      font-family: FontAwesome, sans-serif;
      content: '\f107';
    }
  }

  label {
    margin-bottom: 5px;
    color: #424242;
    font-size: 14px;
    line-height: 19px;
  }

  mat-tab-body {
    margin-top: 10px;
  }
  &.k-widget ::selection,
  &.k-block ::selection,
  &.k-panel ::selection {
    color: inherit;
    background-color: #b3d4fc;
  }

  &.k-widget {
    border-width: 0;
  }

  .k-combobox {
    width: 500px;
    height: 30px;
    border: 1px solid #9b9b9b;

    .k-select {
      background: none;
    }

    .k-combobox-wrap,
    .k-combobox-wrap:hover,
    .k-state-focused {
      background-image: none;
      background-color: #ffffff;
      box-sizing: border-box;
      border: none;
      box-shadow: none;

      .k-i-arrow-60-down::before,
      .k-i-arrow-s::before,
      .k-i-sarrow-s::before,
      .k-i-collapse::before,
      .k-i-expand-s::before {
        font-family: FontAwesome, sans-serif;
        content: '\f107';
      }

      .k-input {
        color: #4a4a4a;
        font-size: 13px;
        line-height: 18px;
        background: white;
      }
    }

    .template {
      background-color: #ffffff !important;
    }

    .template:hover {
      background-color: #c7ebff;
    }
  }

  .pipeline-list-grid {
    border: none;

    & > .k-grid-aria-root > .k-grid-header {
      border-bottom-color: $fusion-color-mid-grey;

      * {
        border: none;
      }
    }

    & .k-hierarchy-cell > .k-icon {
      padding-left: 15px !important;
    }
  }

  .pipeline-detail-list {
    .k-header[aria-colindex='2'] {
      border-left-width: 1px;
    }

    .k-grid td {
      height: auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
  }

  .k-filter-row {
    background-color: #fafafa !important;
  }

  kendo-popup {
    .k-popup {
      border: 1px solid #9b9b9b;
      color: #000;
      background-color: #fff;
      overflow: auto;
      font-size: 13px;
      font-family: inherit;
    }
  }

  button[disabled],
  .k-widget[disabled] {
    cursor: not-allowed;
    pointer-events: auto;
  }

  .clickable {
    cursor: pointer;
  }

  .cdk-overlay-pane {
    max-width: 95vw !important;
  }

  .alertMessage {
    a {
      color: inherit;
      text-decoration: underline !important;
    }
  }

  .k-dropdownlist {
    .k-input-inner {
      background: white !important;
      height: 100%;
    }
  }
}

.k-filter-menu-container {
  min-width: 230px;
  width: auto !important;
  padding: 0 0 0 0;

  .k-action-buttons {
    padding-bottom: 8px;

    .k-button {
      padding: 4px 40px;
    }
  }
}

.k-grid.pipeline-list-grid {
  .kendo-filter-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .k-grid-header {
    .k-grid-filter {
      display: flex;
      margin-left: 12px;
      right: unset;
      bottom: calc(17px + 0.7142857143em - calc(1.4285714286em + 17px) / 2);
    }
  }
}

.mat-mdc-dialog-container {
  padding: 0 !important;
  background: #fff;
  border-radius: 5px;
}

.mat-mdc-dialog-content {
  padding: 0 !important;
  margin: 0 !important;
  line-height: normal !important;
  color: #000 !important;
}

.requirement-details-container .mat-mdc-dialog-container {
  overflow-x: hidden;
  overflow-y: hidden;
}

.tileDocuLinkIcon {
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-left: 3px;
}

.link {
  vertical-align: middle;
  line-height: 1;
}

.text-align-right {
  text-align: right !important;
}

.fusion-tooltip-text {
  white-space: pre-wrap !important;
}

.clickable,
a {
  cursor: pointer;
  text-decoration: none !important;
}

a {
  color: #428cc9;

  &.red-text {
    color: $labelErrorColor;
  }

  &:visited {
    filter: brightness(100%);
  }

  &:active {
    filter: brightness(100%);
  }
}

.clickable:hover,
a:hover {
  filter: brightness(85%);
}

.link-icon {
  vertical-align: text-bottom;
}

.text-overflow {
  text-overflow: ellipsis;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
}

.mat-mdc-chip {
  min-height: 20px;
  font-family: 'Open Sans', sans-serif !important;
  letter-spacing: 0 !important;
  &.mat-mdc-standard-chip {
    background-color: $chipBackground !important;
    text-overflow: ellipsis;
    min-width: 0;
    white-space: nowrap;
    width: fit-content !important;
  }

  &.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
    opacity: 1 !important;
    pointer-events: all !important;
  }
}
.mdc-evolution-chip__cell--primary,
.mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip,
.mat-mdc-chip-action-label {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.k-checkbox:checked,
.k-button-solid-primary {
  background-color: #0294c7 !important;
  border-color: #0294c7 !important;
}

.k-sort-icon,
.k-sort-order {
  color: #0294c7 !important;
}

.neutral {
  color: darkgray;
}

.warning {
  color: #f5a623;
}

.error {
  color: #d9305a;
}

.dialog-error-text {
  color: $textColor;
}

.restore-snackbar-message,
.revert-release-status-snackbar-message {
  max-width: 800px !important;
}

.bold {
  font-weight: bold;
}

.snackbar-in-dialog {
  background: #333333;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 20vh !important;

  .mat-mdc-snack-bar-action {
    color: #428cc9 !important;
  }

  .mat-mdc-snack-bar-action:hover {
    filter: brightness(85%);
  }
}

.font-size-18 {
  font-size: 18px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-14 {
  font-size: 14px;
}
.vertical-align-initial {
  vertical-align: initial !important;
}

.vertical-align-top {
  vertical-align: top !important;
}

.vertical-align-baseline-middle {
  vertical-align: top !important;
  vertical-align: -moz-middle-with-baseline !important;
  vertical-align: -webkit-baseline-middle !important;
}

.td-padding-top-20 {
  padding-top: 20px !important;
}

.invisible {
  display: none !important;
}

.text-truncated {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.text-line-clamp-3 {
    -webkit-line-clamp: 3;
  }

  &.text-line-clamp-2 {
    -webkit-line-clamp: 2;
  }

  &.text-line-clamp-1 {
    -webkit-line-clamp: 1;
  }
}

.text-not-truncated {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: visible;
  -webkit-line-clamp: initial;
}

.text-align-center {
  text-align: center !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  letter-spacing: 0 !important;
}

.mat-chip-small {
  height: auto !important;
  font-size: smaller;
}

.mat-mdc-dialog-actions {
  margin-bottom: -24px !important;
}

.reading-label {
  display: block !important;
  a {
    vertical-align: middle;
  }
}

.k-input-value-text::before {
  display: none !important;
}

.pipelinerun-info-text,
.pipeline-run-info-date {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pipeline-run-info-date {
  width: 200px;
}

.overflow-hidden {
  overflow: hidden;
}

.list-style {
  list-style: disc !important;
  &.margin-width {
    margin: 0px 0px 0px 15px!important;
    width: 485px!important;
  }
  li {
    margin-top: 7px;
  }
}

// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
  @include mat.badge-overrides((
    background-color: $fusion-color-primary,
    // background-color: #0078b9,
    text-color: white,
  ));
}

.treelist-first-cell-wrapper {
  display: inline-block;
  white-space: normal;
  padding-left: 12px;
}

.k-grid  kendo-treelist-list td[kendotreelistcell].k-table-td:first-child {
  white-space: nowrap;
  overflow: initial;

  // generate css for each indentation level
  @for $i from 1 through 3 {
    kendo-icon-wrapper:nth-child(#{$i}) + .treelist-first-cell-wrapper {
      // subtract the indentation from the width. indentation is 23px for each level (16px for icon and 3.5px padding on each side of the icon)
      width: calc( 100% - ( #{$i} * 23px ) );
    }
  }

}

.badge-container {
  width: fit-content;
  @include mat.badge-overrides((
    background-color: $linkColor,
  ));
}

